/*jshint browser: true, unused: false */
/*global require: false */

require(['jquery',
    'modal',
    'search',
    'tooltip',
    'toggle',
    'analytics',
    'doormat',
    'footable',
    'footableSort',
    'footablePagination',
    'footnoteLinks'],
    function ($) {
        
    'use strict';
    // add External Link description
    $('[rel="external"]').attr('title', 'You\'ll leave hmsa.com.');

    // use tooltip API to dynamically fetch & put glossary definitions into tooltips
    $('a.glossary, a[href^="/help-center/glossary/#"]').tooltip({
        tooltipContent: function () {
            var dfd = new $.Deferred(),
                href = this.$trigger.attr('href'),
                url = '/api/glossary/' + href.substr(href.indexOf('#') + 1) + '/';

            $.ajax({
                url: url,
                success: function (d) {
                    var $body = $($.trim(d.Definition)),
                        html = $body
                                .find('a.glossary[href]')
                                .attr('href', function (i, attr) {
                                    return '/help-center/glossary/' + attr;
                                })
                                .end()
                                .html();

                    dfd.resolve('<h4 class="collapse">' + d.Title + '</h4>' + html);
                },
                error: function () {
                    dfd.reject('ajax error');
                }
            });

            return dfd.promise();
        }
    });

    $('.mobile-menu').on('click', '.more', function () {
        $(this)
            .toggleClass('on')
            .parent()
                .toggleClass('on')
            .end()
            .find('i, span')
                .toggleClass('icon-plus icon-minus')
            .end()
            .next('ul')
                .toggle()
            .end();

        return false;
    });

    $('a[data-target=".site-search"]').on('click', function () {
        $('.mobile-menu').addClass('mobile-hide');
        $('a[data-target=".mobile-menu"]').removeClass('on');
    });

    $('a[data-target=".mobile-menu"]').on('click', function () {
        $('.site-search').addClass('tablet-hide');
        $('a[data-target=".site-search"]').removeClass('on');
    });

   $('[href="#menu"]').on('click', function () {
       var locationPath = location.pathname.substr(1).substr(0, location.pathname.length - 2).split('/');
       locationPath.push(location.hash.substr(1));

        $('.mobile-menu > .menu-mobile-item').each(function () {
            openMenuLayer($(this), locationPath);
        });
    });

   function openMenuLayer($self, locationPath) {
       if (isInArray($self.attr('data-section'), locationPath)) {
           $self
               .children('.more')
                    .children('i, span')
                        .toggleClass('icon-plus icon-minus')
                    .end()
               .end()
               .children('ul')
                   .toggle()
                   .children('li')
                       .each(function () { openMenuLayer($(this), locationPath); });
       }
   }

   function isInArray(item, list) {
       item = getPseudoName(item);

        for (var i = 0; i < list.length; i += 1) {
            if (item === list[i]) {
                return true;
            }
        }

        return false;
    }
    
    function getPseudoName(item) {
        switch (item) {
            case 'medicare---akamai-advantage':
                return 'medicare';
            case 'plan-comparison':
                return 'metallic-plans';
            case 'individuals-and-families':
                return 'individuals-families';
            default:
                return item;
        }
    }

    // initialize fooTables
    $(function () {
        var options = {
            breakpoints: {
                phone: 620,
                tablet: 850
            },
            classes: {
                sort: {
                    sortable: 'sortable',
                    sorted: 'sorted-asc',
                    descending: 'sorted-desc',
                    indicator: 'hide'
                }
            },
            // NOTE: "footable-toggle" class required on both span and button to satisfy footable.js
            toggleHTMLElement: '<span class="footable-toggle">' +
                '<button class="footable-toggle" type="button" aria-label="Show or Hide Detail">' +
                '<span class="icon-plus"></span>' +
                '<span class="icon-minus"></span>' +
                '</button>' +
                '</span>'
        };
        $('.collapsible-table, .table-collapsible').footable(options);
    });
    
    if (document.readyState === 'complete') {
        $('body').addClass('loaded');
    }
    else {
        $(window).on('load', function () {
            $('body').addClass('loaded');
        });
    }
});
define("core", function(){});

