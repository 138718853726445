/*jslint browser: true */
/*global define: false */

define('doormat',['jquery', 'menuAim'], function ($) {
    'use strict';

    var openDoormat = null;

    function hideDoormats() {
        if (!openDoormat) {
            return;
        }

        $('.main-nav .hover').removeClass('hover');

        $('#' + openDoormat)
            .removeClass('doormat--open')
            .attr('aria-hidden', 'true')
            .parent()
            .removeClass('doormat--open');

        $('.doormat-wrapper').css('height', '');

        $('.touch body').off('click.doormat');

        openDoormat = null;

        return true;
    }

    function showDoormat(doormat) {
        hideDoormats();

        $('.main-nav > li[aria-owns="' + doormat + '"] > a').addClass('hover');

        var height = $('#' + doormat)
                        .attr('aria-hidden', 'false')
                        .addClass('doormat--open')
                        .parent()
                            .addClass('doormat--open')
                        .end()
                        .data('doormat-height');

        $('.doormat-wrapper').css('height', height + 'px');

        $('.touch body').on('click.doormat', hideDoormats);

        openDoormat = doormat;
    }

    function activateDoormat(e) {
        var doormat = $(e).attr('aria-owns') || $('.doormat.doormat--open').attr('id');

        showDoormat(doormat);
    }

    $('.touch .doormat-wrapper').on('touchend', 'a', function (e) {
        e.stopPropagation();
    });

    $('.touch .main-nav').on('touchend', 'li', function (e) {        
        e.stopPropagation();

        if ($(this).find('a.hover').length === 0) {
            activateDoormat(this);

            $('.touch body').on('touchend.doormat', function (e) {
                var $this = $(e.target);

                if ($this.hasClass('doormat') || $this.hasClass('doormat-wrapper')) {
                    return false;
                }               

                $('.touch body').off('touchend.doormat');

                if (!$this.is('a')) {                    
                    hideDoormats();

                    return false;
                }
            });

            return false;
        }
    });

    /*
     * Menu Aiming - detects mouse moving diagonally and tries to determine
     * if user is trying to hover over the doormat rather than the next menu item
     */
    $('.main-nav').menuAim({
        activate: activateDoormat,
        deactivate: hideDoormats,
        exitMenu: hideDoormats,
        submenuDirection: 'below',
        tolerance: 50
    });

    /*
     * Keyboard Interactions
     */
    $('.main-nav').on('keydown', 'a', function (e) {
        // on enter key, open corresponding doormat and put focus on the first item
        if (e.which === 13) {
            showDoormat($(this).parent().attr('aria-owns'));
            $('.doormat.doormat--open a:first').focus();
            return false;
        }
    });

    $('.doormat-wrapper').on('keydown', 'a', function (e) {
        if (e.which === 9) // tab
        {
            // check if tabbing foward off last anchor in the doormat
            if (!e.shiftKey && this === $('.doormat.doormat--open a:last').get(0)) {
                // desired behavior is for focus to go to the next main-nav item
                var next = $('.main-nav .hover').parent().next().find('a');

                hideDoormats();

                if (next.length >= 1) {
                    next.focus();
                    return false;
                }

                // if there is no next .main-nav item, allow default tab behavior
            }

            // check if tabbing backwards off first anchor in the doormat
            if (e.shiftKey && this === $('.doormat.doormat--open a:first').get(0)) {
                // desired behavior is for focus to go to the previous main-nav item
                var prev = $('.main-nav .hover').parent().prev().find('a');

                hideDoormats();

                // if there is no prev .main-nav items, go to the login link which is the link just before the main-nav in tab order
                if (prev.length >= 1) {
                    prev.focus();
                } else {
                    $('.nav-login').focus();
                }

                return false;
            }
        } else if (e.which === 27) { // esc
            // on escape, close doormat and restore focus to corresponding main-nav item
            $('.main-nav .hover').focus();
            hideDoormats();
            return false;
        }
    });


    /*
     * Tablet Interactions - allow user to tap anywhere outside of doormat
     * in order to dismiss it
     */
   

    /*
     * Initial Setup - pre-measure heights of each doormat menu
     * and store them as data attributes for use later in animating
     * the doormate menu downward.
     */
    $('.doormat').each(function () {
        var $this = $(this);

        // the doormats are by default hidden (display: none), but in that
        // state we are unable to measure their heights. We use a 'hide-children'
        // class which allows the doormats to be temporarily displayed but hidden
        // from view via clipping overflow.
        $this.parent().addClass('hide-children');

        $this.attr('data-doormat-height', $this.height());

        $this.parent().removeClass('hide-children');
    });
});
