/*jshint browser: true , unused: false */
/*global define: false, setTimeout: false */
define('toggle',['jquery'], function ($) {
    'use strict';

    function toggleElement($trigger) {
        var target = $trigger.data('target'),
            parent = $trigger.data('target-parent'),
            toggle = $trigger.data('toggle');
        /*label = analytics.gaLabel($trigger);*/

        $trigger.toggleClass('on');

        if (!target) {
            target = $trigger.next().toggleClass(toggle);
        } else {
            if (!parent) {
                $(target).toggleClass(toggle);
            } else {
                $trigger.parents(parent).find(target).toggleClass(toggle);
            }
        }

        // Google Analytics
        //if (window.ga && label) {
        //    if ($trigger.hasClass('on')) {
        //        /*gtag('send', 'event', 'toggle', 'open', label);*/
        //    } else {
        //        /*gtag('send', 'event', 'toggle', 'close', label);*/
        //    }  
        //}
    }

    $(document)
        .on('click.toggle', '[data-toggle]', function () {
            var $this = $(this),
                group = $this.data('toggle-group'),
                viewport = $this.data('toggle-viewport');

            if ((viewport === 'mobile' && window.matchMedia('(max-width: 620px)').matches) || (viewport === 'tablet' && window.matchMedia('(max-width: 850px)').matches) || (viewport !== 'mobile' && viewport !== 'tablet')) {

                // if in a group, turn off any other items in the group that are on
                if (group) {
                    $('.on[data-toggle-group="' + group + '"]').each(function () {
                        if (group.indexOf('collapsible-table') > -1) {
                            $('.collapsible-table').resize();
                        }

                        toggleElement($(this));
                    });
                }

                toggleElement($this);
            }


            return false;
        })

        .on('keypress.toggle', '[data-toggle]', function (event) {
            if (event.which === 13) {
                $(this).trigger('click');
            }
        })

        .find('[data-toggle]:not(a)')
        .attr('tabindex', '0');

    var accordions = document.querySelectorAll('.toggle-body');

    accordions.forEach(function (div) {
        div.classList.add('hide');
    });
});
