/*jshint browser: true , unused: false */
/*global define: false, setTimeout: false */
define('tooltip',['jquery'], function ($) {
    'use strict';

    var activeTooltip,
        Tooltip = function (element, options) {
            this.on = false;
            this.$tooltip = null;
            this.$trigger = $(element);
            this.options = $.extend({}, Tooltip.defaults, this.$trigger.data(), options);

            // setup events
            if (this.options.clickable) {
                this.$trigger.on('click', $.proxy(this.click, this));
            } else {
                this.$trigger.on('mouseenter focus', $.proxy(this.enter, this));
                this.$trigger.on('mouseleave blur', $.proxy(this.leave, this));
                this.$trigger.on('click', $.proxy(this.leave, this));
            }
        };

    Tooltip.defaults = {
        template: '<div class="tooltip"><div class="tooltip-body"></div></div>',
        bodySelector: '.tooltip-body',  // a selector to signify where in the template to insert the content
        position: 'auto',               // where to position tooltip relative to trigger. valid values: auto, top, bottom
        templateClass: '',              // extra class(es) to apply to the template div, to add custom styling variations
        arrowHeight: 15,
        arrowLeft: 50
    };

    Tooltip.prototype.click = function () {
        if (this.on) {
            this.leave();
            $(document).off('click.tooltip');
        } else {
            this.enter();
            $(document).on('click.tooltip', $.proxy(this.click, this));
            return false;
        }
    };

    Tooltip.prototype.enter = function () {

        this.on = true;

        if (activeTooltip !== this) {

            if (activeTooltip) {
                activeTooltip.hide();
            }

            activeTooltip = this;
        }

        if (this.$tooltip) {
            this.show();
            return;
        }

        // The tooltipContent option can be a string or a function. We allow the function
        // to either return a string or a deferred, the latter for scenarios where we want to load
        // in content via ajax. Rather than try to detect if getContent returned a string or deferred,
        // we use $.when() which according to jquery documentation:
        //      If a single argument is passed to jQuery.when and it is not a Deferred or a Promise,
        //      it will be treated as a resolved Deferred and any doneCallbacks attached will be
        //      executed immediately.
        $.when(this.getContent()).done($.proxy(this.createTooltip, this));
    };

    Tooltip.prototype.leave = function () {

        this.on = false;
        setTimeout($.proxy(this.hide, this), 300);
    };

    Tooltip.prototype.show = function () {
        this.$tooltip.removeClass('hide');
        this.reposition();
    };

    Tooltip.prototype.hide = function (override) {
        if (!this.on || override) {
            // for now we just hide the tooltip's DOM elements, but in the future we could
            // use .detach() instead if there ends up being a lot of tooltips per page
            if (this.$tooltip !== null) {
                this.$tooltip.addClass('hide');
            }
        }
    };

    Tooltip.prototype.getContent = function () {
        if (typeof this.options.tooltipContent === 'function') {
            return $.proxy(this.options.tooltipContent, this)();
        }

        return this.options.tooltipContent;
    };

    Tooltip.prototype.createTooltip = function (content) {
        if (!content || !$.trim(content)) {
            return;
        }

        this.$tooltip = $(this.options.template)
                            .addClass(this.options.templateClass)
                            .find(this.options.bodySelector)
                                .html(content)
                            .end()
                            .appendTo('body');

        this.$tooltip.on('mouseenter focus', $.proxy(this.enter, this));
        this.$tooltip.on('mouseleave blur', $.proxy(this.leave, this));

        this.reposition();
    };

    Tooltip.prototype.getOrientation = function () {
        if (this.options.position !== 'auto') {
            return this.options.position;
        }

        var orientation = '',
           height = this.$tooltip.outerHeight() + this.options.arrowHeight + 5,
           distanceToTop = this.$trigger.offset().top - $(window).scrollTop(),
           distanceToBottom = ($(window).scrollTop() + $(window).height()) - (this.$trigger.offset().top + this.$trigger.outerHeight()),
           rightSide = (this.$trigger.offset().left > $(window).outerWidth() / 2);

        if (rightSide) {
            orientation = 'right-';
        }

        if (distanceToTop > height) {
            return orientation += 'top';
        }

        if (distanceToBottom > height) {
            return orientation += 'bottom';
        }

        return null;
    };

    Tooltip.prototype.getPosition = function () {
        var orientation = this.getOrientation(),
            position = { };

        if (!orientation) {
            // no orientation could be found that would fit our tooltip on the screen, so we hide it
            return {
                top: -1000,
                left: -1000
            };
        }

        // remove any existing orientation css classes & new one
        this.$tooltip
            .removeClass(function (i, css) {
                return (css.match(/\btooltip-orientation-\S+/g) || []).join(' ');
            })
            .addClass('tooltip-orientation-' + orientation);

        if (orientation.indexOf('right') !== -1) {
            position.right = $(window).outerWidth() - this.$trigger.offset().left - this.options.arrowLeft;
            position.left = 'auto';
        }
        else
        {
            position.left = this.$trigger.offset().left + (this.$trigger.outerWidth() / 2) - this.options.arrowLeft;
        }

        if (orientation.indexOf('top') !== -1) {
            position.top = this.$trigger.offset().top - (this.$tooltip.outerHeight() + this.options.arrowHeight);
        }

        if (orientation.indexOf('bottom') !== -1) {
            position.top = this.$trigger.offset().top + this.$trigger.outerHeight() + this.options.arrowHeight;
        }

        return position;
    };

    Tooltip.prototype.reposition = function () {
        this.$tooltip.css(this.getPosition());
    };

    // JQuery Plugin Definition
    $.fn.tooltip = function (option) {
        return this.each(function () {
            var $this = $(this),
                data = $this.data('tooltip'),
                options = typeof option === 'object' && option;

            if (!data) {
                data = new Tooltip(this, options);
                $this.data('tooltip', data);
            }

            if (typeof option === 'string') {
                data[option]();
            }
        });
    };

    // Data API
    $('[data-widget~="tooltip"]').tooltip();

    return Tooltip;
});
