/*jshint browser: true */
/*global define: false */
define('footnoteLinks',['jquery'], function ($) {
    'use strict';

    $('body').append('<h2 class="links-header print-only">Links</h2><ol id="printlinks" class="links-list print-only"></ol>');

    var excludes = ['[href="#"]',							// href is just a hash
                    '[href^="javascript:"]',				// href is javascript
                    '[href^="#modal-"]',					// href is a modal
                    '[href^="tel:"]',						// href is a telephone number
                    '[href^="mailto:"]',					// href is an email address
                    '[href*="twitter"]',					// href is a twitter address
                    '[href*="facebook"]',					// href is a facebook address
                    ':has(img)'].join(', '),				// anchor contains an image
        allLinks = {},
        nextLinkNumber = 1,
        $tocList = $('#printlinks');

    function footnoteLinks(container) {
        var $links = $(container + ' a[href]').not(excludes);

        if ($links.length <= 0) {
            return;
        }
        
        $links.each(function () {
            var href = $(this).attr('href'),
                linkNumber = allLinks[href] || nextLinkNumber,
                supHtml = '',
                OtherCheck = $(this).parents('.print-hide').html() === undefined &&		// exlude links with parent class .print-hide
                    $(this).parents('.no-print-links').html() === undefined &&			// exlude links from areas we want to exclude
                    !$(this).hasClass('button-tag') &&									// exlude links with class .button-tag
                    !$(this).hasClass('button-tag-cancel') &&							// exlude links with class .button-tag-cancel
                    $(this).parents('.tabs').html() === undefined &&					// exlude links with parent class .tabs
                    $(this).parents('.breadcrumb').html() === undefined &&				// exlude links with parent class .breadcrumb
                    $(this).parents('.pager').html() === undefined;						// exlude links with parent class .pager

            // if this is a new link, add it to the table of contents list
            if (linkNumber === nextLinkNumber && OtherCheck) {
                $tocList.append('<li>' + this.href + '</li>');
                allLinks[href] = linkNumber;
                nextLinkNumber += 1;
            }

            supHtml = '<sup class="links-number print-only">' + linkNumber + '</sup>';

            if ($(this).attr('print') !== undefined && $(this).attr('print') === 'relocate-sup') {
                // add superscript notation to link
                $(this).find('[print="link"]').after(supHtml);
            }
            else if (OtherCheck) {
                // add superscript notation to link
                $(this).after(supHtml);
            }
        });

        // clean out print links html if no links found
        if (nextLinkNumber === 1) {
            $('.links-header').addClass('print-hide').remove();
            $('#printlinks').remove();
        }
    }

    if (document.readyState === 'complete') {
        footnoteLinks('.hero');
        footnoteLinks('.pattern-main');
        footnoteLinks('.pattern-gray');
        footnoteLinks('.pattern-community');
    } else {
        $(window).on('load', function () {
            footnoteLinks('.hero');
            footnoteLinks('.pattern-main');
            footnoteLinks('.pattern-gray');
            footnoteLinks('.pattern-community');
        });
    }
});
