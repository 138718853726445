/*global define: false, ga: false */

// additional GoogleAnalytic functionality including the tracking of
// clicks on external links, documents, emails and telephone numbers.
define('analytics',['jquery'], function ($) {
    'use strict';

    var isExternal = /^https?\:\/\//i,
        isDocument = /\.(pdf|doc|docx|zip)$/i,
        isTelephone = /^tel\:/i,
        isEmail = /^mailto\:/i,
        isTwitter = /^https?\:\/\/twitter\.com\/home/i,
        isFacebook = /^https?\:\/\/www\.facebook\.com\/sharer\/sharer\.php/i;

    // Intercept the clicks of normal hyperlinks (those that do NOT have target="_blank")
    $('body').on('click', 'a:not([target="_blank"])', function () {
        var $this = $(this),
            href = $this.attr('href');

        // Test if URL should actually be opened in a new window
        if(isDocument.test(href) /*|| isExternal.test(href)*/) {
            // Add target="_blank" to the link
            $this.attr('target', '_blank');
        }

        return true;
    });

    $('body').on('click', 'a', function () {
        var $this = $(this),
            href = $this.attr('href'),
            targetBlank = $this.attr('target') === '_blank',
            options = {},
            called,
            timeoutId;

        var openLink = function () {
            // If already called via GA's callback, ignore this forced call
            if (called) { return; }
            called = true;
            // Clear timeout to prevent duplicate call (race condition)
            timeoutId && window.clearTimeout(timeoutId);
            // Follow the link
            document.location = href;
        }

        var track = function () {
            // Call ga() as normal
            ga.apply(ga, arguments);

            if (options.hitCallback) {
                // Make explicit call to GA's callback after 2 seconds
                timeoutId = window.setTimeout(options.hitCallback, 2000);
            }
        };

        // For links without target="_blank", delay link navigation until Google Analytics call completes
        if (!targetBlank) {
            options.hitCallback = openLink;
            options.hitCallbackFail = openLink;
        }

        if (isTwitter.test(href)) {
            track('send', 'social', 'twitter', 'tweet', options);
        } else if (isFacebook.test(href)) {
            track('send', 'social', 'facebook', 'share', options);
        } else if (isExternal.test(href)) {
            track('send', 'event', 'external', 'click', href.replace(isExternal, ''), options);
        } else if (isDocument.test(href)) {
            track('send', 'event', 'download', 'click', href.replace(isExternal, '').replace(document.location.hostname, ''), options);
        } else if (isEmail.test(href)) {
            track('send', 'event', 'email', 'click', href.replace(isEmail, ''), options);
        } else if (isTelephone.test(href)) {
            track('send', 'event', 'telephone', 'click', href.replace(isTelephone, ''), options);
        } else {
            // Don't log to Google Analytics, just continue with default click behavior
            return true;
        }

        // Return false for normal links (those without target="_blank"), since their navigation is handled in GA callback
        return targetBlank;
    });

    return {
        // Parses text or sets href or unknown to label when sending data as an event
        gaLabel: function ($this) {
            var text = $this.text(),
                href = $this.attr('href'),
                gaLabel = 'unknown';

            if (text) {
                gaLabel = text.replace(/'|"/g, '').trim().split('  ');
                gaLabel = gaLabel[0].replace(/[^a-zA-Z0-9]/g, ' ').trim().replace(/\s+/g, ' ');
            }
            else if (href) {
                gaLabel = href.trim();
            }

            return gaLabel;
        }
    };
});

