/*jshint browser: true , unused: false */
/*global define: false, setTimeout: false */
define('modal',['jquery'], function ($) {
    'use strict';
    
    /* -- HMSA MODAL -- */
    var activeModal,
        modals = [],
        showOnLoad = true,
        loadOnly = false,
        preloadcount = 0,
        scrollPosition,
        Modal = function (ref, onload) {
            this.onload = onload;
            this.contentRef = ref;
            this.getContent();
        },
        AjaxModal = function (ref, onload) {
            this.onload = onload;
            this.contentRef = ref;
            this.sourceRef = ref;
            this.getContent();
        };

    /************* Base Modal Object ************/
    Modal.prototype = {
        buildModal: function (content) {
            var $modal = $('#' + this.modalId),
            header = '<div class="modal-header"><h4 class="collapse pull-left">{name}</h4></div>',
            body = '<div class="modal-body {classes}"></div>',
            close = '<button type="button" class="close pull-right" data-dismiss="modal" aria-hidden="true">&times;</button><span class="close-text pull-right" data-dismiss="modal">close</span>',
            triggerSelector = '[href="{ref}"]';

            if (!this.name) {
                this.name = '';
            }
            
            if (this.options.header === true) {
                this.header = $modal.prepend(header.replace('{name}', this.name));

                if (this.options.close) {
                    this.header.append(close);
                }
            }
            else if (this.options.close)
            {
                $modal.prepend('<span class="close-button" data-dismiss="modal"></span>');
            }

            if (this.options.bodyPad === true) {
                this.body = $modal.append(body.replace('{classes}', ''));
            }
            else
            {
                this.body = $modal.append(body.replace('{classes}', 'no-pad'));
            }
          
            this.injectContent(content);
            this.initSize();
            
            $(triggerSelector.replace('{ref}', this.contentRef)).attr('href', '#' + this.modalId);

            modals['#' + this.modalId] = this;

            modals.length += 1;

            if (this.onload === showOnLoad) {
                this.show();
            }
        },

        parseOptions: function (options) {
            var opts = {
                    header: false,
                    close: false,
                    backdrop: true,
                    bodyPad: true
                },
                sOpts,
                i = 0;
           
            if (!options) {
                return opts;
            }

            sOpts = options.split(' ');

            for (i = 0; i < sOpts.length; i += 1) {
                switch (sOpts[i]) {
                    case 'no-header':
                        opts.header = false;
                        break;
                    case 'no-close':
                        opts.close = false;
                        break;
                    case 'close':
                        opts.close = true;
                        break;
                    case 'no-backdrop':
                        opts.backdrop = false;
                        break;
                    case 'no-body-pad':
                        opts.bodyPad = false;
                        break;
                    default:
                        break;
                }
            }
            return opts;
        },

        getContent: function () {
            var modalContainer = '<div id="{id}" class="modal fade hide"></div>',
                $contentRef = $(this.contentRef),
                rawOptions = $contentRef.attr('data-options');

            this.modalId = ('modal-' + this.contentRef.substr(1));
            this.name = $contentRef.attr('data-name');

            this.options = this.parseOptions(rawOptions);
            this.backdrop = undefined;

            $('body').append(modalContainer.replace('{id}', this.modalId));

            this.buildModal();

            this.initSize();
        },

        initSize: function () {
            var width = $('#' + this.modalId + ' .modal-body div').width();

            if (this.modalId === 'modal-medicare-eligibility-modal') { width = 400; } // bug that needs to be addressed, possibly via options

            if (this.modalId === 'modal-drug-cost-modal') { width = 900; }

            this.resize(width);
        },

        show: function () {
            var that = this;

            $('#' + this.modalId).removeClass('hide');
            that.popBackdrop();
            $('.modal-backdrop').addClass('in');

            setTimeout(function () {
                var modal = $('#' + that.modalId)
                        .addClass('in')
                        .trigger('modal-show');

                // Set focus to first interactive element in dialog body (input, checkbox, button, etc)
                modal
                    .find('.modal-body')
                    .find(
                        '[tabindex][tabindex!="-1"]:visible,' +
                        'input[type!="hidden"]:visible,' +
                        'select:visible,' +
                        'button:visible,' +
                        'a:visible'
                    )
                    .first()
                    .focus();
            }, 20);      
        },

        hide: function () {
            var that = this;

            if (scrollPosition) {
                $('body, html').animate({ scrollTop: scrollPosition }, 200);
                scrollPosition = undefined;
            }

            $('.modal-backdrop').removeClass('in');

            // hide modal and trigger custom event so that external code can
            // do any modal cleanup needed (e.g. stop playing video on homepage)
            $('#' + this.modalId)
                    .removeClass('in')
                    .trigger('modal-hide');

            setTimeout(function () {
                $('#' + that.modalId).addClass('hide');
                that.killBackdrop();
            }, 200);
        },

        resize: function (size) {
            var paddingMod = this.paddingMod();

            size = this.clampSize(size) + paddingMod;

            $('#' + this.modalId).attr('style', 'width:' + size + 'px; ');
        },

        clampSize: function (size) {
            if (size < 200) {
                size = 600;
            }
            return size;
        },

        paddingMod: function () {
            if (this.options.bodyPad === true) {
                return 60;
            }
            return 0;
        },

        injectContent: function () {
            var content = $(this.contentRef).detach();

            content.removeClass('hide');

            $('#' + this.modalId + ' .modal-body').append(content);
        },

        popBackdrop: function () {
            if (this.backdrop === undefined && this.options.backdrop) {
                $('body').append('<div class="modal-backdrop fade" data-dismiss="modal"></div>');
            } else {
                $('body').append(this.backdrop);
            }
        },

        killBackdrop: function () {
            if (this.options.backdrop) {
                this.backdrop = $('.modal-backdrop').detach();
            }
        }
    };

    /************ AJAX Modal ************/
    AjaxModal.prototype = (function () {
        var Anonymous = function () { this.constructor = AjaxModal; };
        Anonymous.prototype = Modal.prototype;
        return new Anonymous();
    }());

    AjaxModal.prototype.getContent = function () {
        var that = this;
        $.ajax({
            url: that.contentRef
        }).done(function (data) {
            that.initRemoteContent(data);
        }).fail(function () {
            return false;
        });
    };
    
    AjaxModal.prototype.initRemoteContent = function (remoteContent) {
        var content = $($.trim(remoteContent)),
            that = this,
            container = '<div id="{id}" class="modal fade hide"></div>',
            triggerSelector = '[href="{ref}"]';
        
        content.appendTo('body');

        this.modalId = 'modal-' + $(content).attr('id');
        
        this.options = this.parseOptions($(content).attr('data-options'));
        this.backdrop = undefined;
        this.name = $(content).attr('data-name');
        this.contentRef = '#' + $(content).attr('id');

        $('body').append(container.replace('{id}', this.modalId));
        
        this.buildModal(content);
        modals[this.modalId] = activeModal;

        $(triggerSelector.replace('{ref}', this.sourceRef)).attr('href', '#' + that.modalId);
        $('#rem-con').detach().remove();
    };

    /*************** Modal Open *****************/
    function handleModalRequest(ref, onload) {
        // If any other modal is already open, ignore this request
        if ($('.modal-backdrop').length) {
            return;
        }

        if (ref !== null && ref !== undefined) {
            if (ref[0] === '#') {
                handleLocalModal(ref, onload);
            } else {
                handleAjaxModal(ref, onload);
            }
        }
    }

    function handleLocalModal(ref, onload) {
        if (modals[ref] === undefined || modals[ref] === null) {
            activeModal = new Modal(ref, onload);
            return;
        }
        activeModal = modals[ref];
        activeModal.show();
    }

    function handleAjaxModal(ref, onload) {
        activeModal = new AjaxModal(ref, onload);
    }

    /***************** Content Preload ******************/
    function preload() {
        var preloaded = [],
            href = '';
        $('[data-open="modal"]').each(function () {
            if (!existIn($(this).attr('href'), preloaded)) {
                href = $(this).attr('href');
                preloaded.push(href);
                handleModalRequest(href, loadOnly);
            }
        });
        preloadcount = preloaded.length;
    }

    function existIn(item, list) {
        for (var i = 0; i < list.length; i += 1) {
            if (item === list[i]) {
                return true;
            }
            if (item === ('#modal-' + list[i].substr(1))) {
                return true;
            }
        }
        return false;
    }

    /**************** Open On Page Load ****************/
    function openOnLoad() {
        var fragment = checkForFragmentID();
        if (fragment !== false) {
            $.modalInit = setInterval(function () {
                checkPreloadAndOpen(fragment);
            }, 100);
        }
        return;
    }

    function checkPreloadAndOpen(fragment) {
        if (modals.length === preloadcount) {
            if (modals[fragment] !== null && modals[fragment] !== undefined) {
                modals[fragment].show();
            }
            window.clearInterval($.modalInit);
        }
        return false;
    }

    function checkForFragmentID() {
        var fragment = false;
        if (location.hash !== null && location.hash !== undefined) {
            fragment = location.hash;
            fragment = fragment.split('#m-');
            if (fragment.length > 1) {
                fragment = '#modal-' + fragment[1];
            } else {
                fragment = false;
            }
        }
        return fragment;
    }

    /***************** Event Listeners ******************/

    $('body').on('click', '[data-open="modal"]', function () {
        var $this = $(this),
            ref = $this.attr('href');
            /*label = analytics.gaLabel($this);*/

        handleModalRequest(ref, showOnLoad);

        scrollPosition = $('body').scrollTop();
        if (!scrollPosition) {
            scrollPosition = $('body,html').scrollTop();
        }
        
        $('body, html').animate({ scrollTop: 0 }, 200);

        // Google Analytics
        //if (window.ga && label) {
        //    gtag('send', 'event', 'modal', 'open', label);
        //}
        
        return false;
    });
    
    $('body').on('click', '[data-dismiss="modal"]', function () {
        if (activeModal) {            
            activeModal.hide();
        }
    });

    $(window).keydown(function (e) {
        if (activeModal && e.which === 27) {
            activeModal.hide();
        }
    });
    
    /**************** Public Modal Requests ****************/
    function HideModal() {
        activeModal.hide();
    }

    $.modalClose = HideModal;
    $.modalOpen = handleModalRequest;
    
    preload();
    openOnLoad();

    return {
        show: handleModalRequest,
        hide: HideModal
    };
});

